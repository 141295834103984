/********************************************************************/
/****************** 关于 Vue.js - cli 导入组件、插件 *******************/
/********************************************************************/

import Vue from 'vue';
import VueRouter from 'vue-router';

import AaaAaa001 from '../views/AaaAaa001.vue';

/********************************************************************/
/********** 关于 Vue.js + vue-router.js 插件搭建单页面应用 ***********/
/********************************************************************/

Vue.use(VueRouter);

const routes = [
	
	{ path : "/", component : AaaAaa001, meta: { title: 'BeyondNO' } },

];

const router = new VueRouter({
	routes
});

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

/********************************************************************/
/****************** 关于 Vue.js - cli 导出当前组件 ********************/
/********************************************************************/

export default router;
