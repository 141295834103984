/********************************************************************/
/**************** 关于 Vue.js - cli 使用 Vuex 共享技术 ****************/
/********************************************************************/

import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
	// 封装的共享变量区；
	state: {
		
	},
	// 定义 set 方法区；
	mutations: {
		
	},
	// 各类全局方法区；
	actions: {
		
	},
	modules: {
		
	}
});
