/***
	※ 执行说明：
		
		1. 运行 vue Spa 服务器：npm run serve
		
		2. 浏览器访问：
		- Local			: http://localhost:8080/#/
		- Network		: http://192.168.0.111:8080/#/
		
		3. 打包项目：npm run build
		
		4. 运行 node Web 服务器：node node_server.js
***/

// ※ 注意：导入 npm 的第三方插件包；
import Vue from 'vue';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// ※ 注意：导入 vue 的组件；
import App from './App.vue';

// ※ 注意：导入整个文件夹时；
import router from './router';
import store from './store';

Vue.config.productionTip = false;

/********************************************************************/
/*************** 关于 Vue.js - cli 引入 Element UI 组件 ***************/
/********************************************************************/

// Vue.use( ElementUI );

// ※ 注意：在引入 Element UI 时，可以传入一个全局配置对象；
//				该对象目前支持 size 与 zIndex 字段；
//				size		字段：用于改变组件的 默认尺寸；
//				zIndex	    字段：设置弹框的初始 z-index；
Vue.use( ElementUI, { size : 'small', zIndex : 3000 } );

/********************************************************************/
/*************** 关于 Vue.js 模板语法 ********************************/
/********************************************************************/

new Vue({
	router,
	store,
	render : h => h(App)
}).$mount('#app');
