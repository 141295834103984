<template>
<div>
    <!-- ----------------- -->
    <!-- ----- 屏幕一 ----- -->
    <!-- ----------------- -->
    
	<div v-if="!isMobile">
	    
		<div class="div_001">
		    <div class="div_001_001">
		        <div class="div_logo"></div>
		        <a	href="https://app.miningcrypto.pro/user/register"><div class="div_r_l"></div></a>
				<div>Cryptocurrencies</div>
				<div>Cryptocurrency is a digital currency that uses cryptography to secure transactions and control the creation of trading units.</div>
				<div><a	href="https://app.miningcrypto.pro/user/register"><span>Read More</span> <span>→</span></a></div>
			</div>
		</div>
		
		<div class="div_002">
		    <div>How To start</div>
			<div>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</div>
			<div class="div_002_001">
				<div>
					<div>1</div>
					<div>Register</div>
					<div>Create your account in a few steps.</div>
				</div>
				<div>
					<div>2</div>
					<div>Choose Your Package</div>
					<div>Select the mining package you desire. Each package has its own unigue benefits.</div>
				</div>
				<div>
					<div>3</div>
					<div>Receive Profits</div>
					<div>Start earning money from your chosen package. You will receive your first payment in 24 Hours.</div>
				</div>
				<!-- 虚线 -->
				<div></div>
				<div></div>
			</div>
			<!-- 晕染 -->
			<div class="div_002_light"></div>
		</div>
		
		<div class="div_003">
			<div class="div_003_001">
				<div></div>
				<div>
					<div>What is Bitcoin</div>
					<div>Bitcoin is a digital currency that is decentralized. Any two people cansend each other bitcoins with no involvment of banks or governments. </div>
					<div>Every transaction of bitcoins is recorded on the public ledger calledblockchain.The ledger can be accessed by anyone. </div>
					<div>There will only ever be 2l milion bitcoins and since it's digital it cannotbe inflated or manipuiated, There is no need to acquire all bitcoins,owning a fraction suffices.</div>
				</div>
			</div>
		</div>
		
		<div class="div_004">
			<div class="div_004_001">
				<div>Mining Crypto- Cloud Mining PlatforCloud Mining</div>
				<div></div>
				<div>Mining is the process of creating new cryptocurrencles that are addedto the blockchain. Powerful hardware such as ASiCs are used to performcomplex calculations which then generate bitcoins as a reward.</div>
				<div>These machines are quite expensive and require technical knowledge and space.</div>
				<div>Mining Crypto- Cloud Mining Platfor provides miners with state of the arthardware powered by over 1.2 MegaWatts of solar power. Currently wehave a total of 27,000 Bitcoin mining devices producing 3.78 EH/s.Therefore you can mine conviniently and profitably anywhere with justyour device.</div>
			</div>
		</div>
		
		<div class="div_005">
			<div class="div_005_001">
				<div>Why Us</div>
				<div>What we offer</div>
				<div class="div_005_001_block">
					<div>
						<div></div>
						<div>Safe and secure platform</div>
						<div>All funds are stored in fully secure cold wallets with Kaspersky" protection.</div>
					</div>
					<div>
						<div></div>
						<div>Stable proflts</div>
						<div>Your income is automatically updated on your portal everyday.</div>
					</div>
					<div>
						<div></div>
						<div>Fast Regular Payouts</div>
						<div>All withdrawal requests are processed automatically through the portal.</div>
					</div>
					<div>
						<div></div>
						<div>New state-of-the-art hardware</div>
						<div>We use the newest ASIC and GPU mining gear from Bitmain, Canaan and Nvidia and advanced cooling technologies in our centers.</div>
					</div>
					<div>
						<div></div>
						<div>Expert team</div>
						<div>Our team of Blockchain engineers and IT experts have got you fully covered with the best technologies in the market.</div>
					</div>
					<div>
						<div></div>
						<div>Eco-friendly-mining</div>
						<div>Our miners and cooling systems are powered by the best monocrystalline solar panels thus environment-friendly and very profitable.</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="div_006">
			<div class="div_006_001">
				<div>Our patners</div>
				<div class="div_006_001_block">
					<div><div></div></div>
					<div><div></div></div>
					<div><div></div></div>
					<div><div></div></div>
					<div><div></div></div>
					<div><div></div></div>
				</div>
			</div>
		</div>
		
		<div class="div_007">
			<div class="div_007_001">
				<div>Access from any Device</div>
				<div class="div_007_001_block">
					<div>
						<div></div>
						<div></div>
					</div>
					<div>
						<div>1</div>
						<div></div>
						<div>2</div>
						<div></div>
						<div>3</div>
						<div></div>
						<div>4</div>
					</div>
					<div>
						<div>Invest online anytime using any device</div>
						<div>Clear Real-Time mining charts to monitor your investment</div>
						<div>Withdraw your money safely and insanthy</div>
						<div>Eco-friendly mining with high profits</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="div_008">
			<div class="div_008_001">
				<div>
					<div>
						<div>Mining Crypto</div>
						<div>copyright 2025,powered by Mining Crypto</div>
					</div>
					<div>
						<div>NAVIGATE</div>
					<div><a href="https://app.miningcrypto.pro">Home</a></div>
					<div><a href="https://app.miningcrypto.pro">Our Company</a></div>
					<div><a href="https://app.miningcrypto.pro">What we do</a></div>
					<div><a href="https://app.miningcrypto.pro/contact">Reoch Us</a></div>
					</div>
					<div>
						<div>PRIVACY & TOS</div>
					<div><a href="https://app.miningcrypto.pro/policy/usage-policy/165">Usage Policy</a></div>
					<div><a href="https://app.miningcrypto.pro/policy/privacy-policy/162">Privacy Policy</a></div>
					<div><a href="https://app.miningcrypto.pro/policy/terms-of-service/163">Terms of Service</a></div>
					<div><a href="https://app.miningcrypto.pro/policy/cookie-policy/164">Cookie Policy</a></div>
					</div>
					<div>
						<div>Address</div>
						<div><a href="#">P.za degli Affari, 6, 20123 Milano MI</a></div>
						<div>Support</div>
						<div><a href="#">mail:Support@miningcrypto.pro</a></div>
					</div>
				</div>
				<div>
					<div>© 2025 By Mining Crypto. All Rights Reserved. </div>
					<div>
						<div><a href="#">&nbsp;&nbsp;&nbsp;</a></div>
						<div><a href="#">&nbsp;&nbsp;&nbsp;</a></div>
						<div><a href="#">&nbsp;&nbsp;&nbsp;</a></div>
					</div>
				</div>
			</div>
		</div>
	</div>
    
    <div v-if="isMobile">
        <div class="div_mobile_001">
			<div class="div_mobile_001_001"></div>
            <div class="div_mobile_001_002">
				<div>
					<div>Cryptocurrencies</div>
					<div>Cryptocurrency is a digital currency that uses cryptography to secure transactions and control the creation of trading units.</div>
					<div><a href="https://app.miningcrypto.pro/user/register"><span>Read More</span> <span>→</span></a></div>
				</div>
        	</div>
			<div class="div_mobile_001_003"></div>
        </div>
		
		<div class="div_mobile_002">
			<div class="div_mobile_002_001">
				<div>How To start</div>
				<div>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</div>
				<div class="div_mobile_002_001_block">
					<div>
						<div>1</div>
						<div>
							<div>Register</div>
							<div>Create your account in a few steps.</div>
						</div>
					</div>
					<div>
						<div>2</div>
						<div>
							<div>Choose Your Package</div>
							<div>Select the mining package you desire. Each package has its own unigue benefits.</div>
						</div>
					</div>
					<div>
						<div>3</div>
						<div>
							<div>Receive Profits</div>
							<div>Start earning money from your chosen package. You will receive your first payment in 24 Hours.</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="div_mobile_003">
			<div class="div_mobile_003_001">
				<div></div>
				<div>
					<div>What is Bitcoin</div>
					<div>Bitcoin is a digital currency that is decentralized. Any two people cansend each other bitcoins with no involvment of banks or governments. </div>
				</div>
			</div>
			<div class="div_mobile_003_002">more</div>
		</div>
		
		<div class="div_mobile_004">
			<div class="div_mobile_004_001">
				<div>Mining Crypto- Cloud Mining PlatforCloud Mining</div>
				<div></div>
				<div>Mining is the process of creating new cryptocurrencles that are addedto the blockchain. Powerful hardware such as ASiCs are used to performcomplex calculations which then generate bitcoins as a reward.</div>
			</div>
			<div class="div_mobile_004_002">more</div>
		</div>
		
		<div class="div_mobile_005">
			<div class="div_mobile_005_001">
				<div>Why Us</div>
				<div>What we offer</div>
				<div>
					<div></div>
					<div>
						<div>Safe and secure platform</div>
						<div>All funds are stored in fully secure cold wallets with Kaspersky" protection.</div>
					</div>
				</div>
				<div>
					<div></div>
					<div>
						<div>Stable proflts</div>
						<div>Your income is automatically updated on your portal everyday.</div>
					</div>
				</div>
				<div>
					<div></div>
					<div>
						<div>Fast Regular Payouts</div>
						<div>All withdrawal requests are processed automatically through the portal.</div>
					</div>
				</div>
			</div>
			<div class="div_mobile_005_002">more</div>
		</div>
		
		<div class="div_mobile_006">
			<div class="div_mobile_006_001">
				<div>Our patners</div>
				<div class="div_mobile_006_001_block">
					<div><div></div></div>
					<div><div></div></div>
					<div><div></div></div>
					<div><div></div></div>
					<div><div></div></div>
					<div><div></div></div>
				</div>
			</div>
		</div>
		
		<div class="div_mobile_007">
			<div class="div_mobile_007_001">
				<div>Access from any Device</div>
				<div class="div_mobile_007_001_block">
					<div>
						<div></div>
						<div></div>
					</div>
					<div>
						<div>1</div>
						<div></div>
						<div>2</div>
						<div></div>
						<div>3</div>
						<div></div>
						<div>4</div>
					</div>
					<div>
						<div>Invest online anytime using any device</div>
						<div>Clear Real-Time mining charts to monitor your investment</div>
						<div>Withdraw your money safely and insanthy</div>
						<div>Eco-friendly mining with high profits</div>
					</div>
				</div>
			</div>
		</div>
    
		<div class="div_mobile_008">
			<div>
				<div>
					<div>NAVIGATE</div>
					<div><a href="https://app.miningcrypto.pro">Home</a></div>
					<div><a href="https://app.miningcrypto.pro">Our Company</a></div>
					<div><a href="https://app.miningcrypto.pro">What we do</a></div>
					<div><a href="https://app.miningcrypto.pro/contact">Reoch Us</a></div>
				</div>
				<div>
					<div>PRIVACY & TOS</div>
					<div><a href="https://app.miningcrypto.pro/policy/usage-policy/165">Usage Policy</a></div>
					<div><a href="https://app.miningcrypto.pro/policy/privacy-policy/162">Privacy Policy</a></div>
					<div><a href="https://app.miningcrypto.pro/policy/terms-of-service/163">Terms of Service</a></div>
					<div><a href="https://app.miningcrypto.pro/policy/cookie-policy/164">Cookie Policy</a></div>
				</div>
				<div>
					<div>Address</div>
					<div><a href="#">P.za degli Affari, 6, 20123 Milano MI</a></div>
				</div>
				<div>
					<div>Support</div>
					<div><a href="#">mail:Support@</a></div>
					<div><a href="#">miningcrypto.pro</a></div>
				</div>
			</div>
			<div>
				<div>
					<div>Mining Crypto</div>
					<div>copyright 2025,powered by Mining Crypto</div>
				</div>
				<div>
					<div><a href="#">&nbsp;&nbsp;&nbsp;</a></div>
					<div><a href="#">&nbsp;&nbsp;&nbsp;</a></div>
					<div><a href="#">&nbsp;&nbsp;&nbsp;</a></div>
				</div>
			</div>
			<div>© 2025 By Mining Crypto. All Rights Reserved. </div>
		</div>
		
	</div>
</div>
</template>

<script>
export default {
  data() {
    return {
      isMobile              : false,
      screenHeight          : 0,
      scrollTop             : 0,
      
      imagePath_001         : '',
      imagePath_002         : '',
      imagePath_003         : '',

    };
  },
  created() {
    // 监听；
    window.addEventListener('resize', this.checkIsMobile);
    window.addEventListener('scroll', this.checkScrollTop);
    
    // this.imagePath_001 = require('../assets/background.jpg');
    // this.imagePath_002 = require('../assets/group_001.svg');
    // this.imagePath_003 = require('../assets/group_002.svg');
  },
  mounted() {
    let that = this;
    
	document.title = 'Mining Crypto';
	
    this.checkIsMobile();
    this.checkScreenHeight();
  },
  destroyed() {
    window.removeEventListener('resize', this.checkIsMobile);
    window.removeEventListener('scroll', this.checkScrollTop);
  },
  methods: {
    // 检测移动设备；
    checkIsMobile() {
      const width = window.innerWidth || document.documentElement.clientWidth;
      let isMobile_001 = width <= 768;
      
      const userAgent = window.navigator.userAgent;
      let isMobile_002 = /iPhone|iPad|iPod|Android/i.test(userAgent);
       
      this.isMobile = isMobile_001 || isMobile_002;
      
    },
    // 检测屏幕高度；
    checkScreenHeight() {
      const height = window.innerHeight || document.documentElement.clientHeight;
      this.screenHeight = height;
    },
  }
};
</script>

<style scoped>
/*** ************************************************* ***/

.div_001 {
	width: 100%;
	height: 850px;
	
	background-color : #000000;
	background-image : url('../assets/Slice 4.png');
	background-repeat : no-repeat;
	background-position : center;
	background-size : cover;
	
	display : flex;
	flex-direction : column;
	justify-content : center;
	align-items : center;
}

.div_001_001 {
	width: 1200px;
	height: 650px;
	
	background-image : url('../assets/Slice 5.png');
	background-repeat : no-repeat;
	background-position : center;
	background-size : 100%;
	
	display : flex;
	flex-direction : column;
	justify-content : flex-start;
	align-items : center;
	
	position: relative;
}

.div_logo {
	width: 229px;
	height: 48px;
	
	position: absolute;
	
	top: -75px;
	left: 50px;
	
	background-image : url('../assets/web-logo.png');
	background-repeat : no-repeat;
	background-position : center;
	background-size : 90%;
}

.div_r_l {
	width: 340px;
	height: 120px;
	
	position: absolute;
	
	top: -70px;
	right: 0px;
	
	background-image : url('../assets/Frame 9.png');
	background-repeat : no-repeat;
	background-position : center;
	background-size : 60%;
	
	cursor: pointer;
}

.div_001_001 div:nth-child(3) {
	width: 846px;
	height: 111px;
	
	font-weight: 300;
	font-size: 110px;
	color: #FFFFFF;
	
	line-height: 129px;
	font-style: normal;
	text-transform: none;
	
	margin-top: 120px;
}

.div_001_001 div:nth-child(4) {
	width: 860px;
	
	font-weight: 500;
	font-size: 28px;
	color: #FFFFFF;
	
	margin-top: 80px;
}

.div_001_001 div:nth-child(5) {
	width: 200px;
	height: 45px;
	
	font-weight: 600;
	font-size: 18px;
	color: #FFFFFF;
	
	margin-top: 90px;
	line-height: 26px;

	font-style: normal;
	text-transform: none;
	
	border-radius: 25px;
	border: 2px solid #471EFF;
	
	display : flex;
	flex-direction : row;
	justify-content : space-around;
	align-items : center;
	
	cursor: pointer;
}

/*** ************************************************* ***/

.div_002 {
	width: 100%;
	background-color: #040711;
	
	display : flex;
	flex-direction : column;
	justify-content : flex-start;
	align-items : center;
	
	position: relative;
}

.div_002 > div:first-child {
	width: 350px;
	height: 60px;

	font-weight: 800;
	font-size: 48px;
	color: #742BFA;
	
	line-height: 60px;
	
	font-style: normal;
	text-transform: none;
	
	margin-top: 100px;
}

.div_002 > div:nth-child(2) {
	width: 500px;

	font-weight: 600;
	font-size: 20px;
	color: #ffffff;
	
	font-style: normal;
	text-transform: none;
	
	margin-top: 30px;
}

.div_002_001 {
	width: 1000px;
	
	margin-top: 80px;
	
	position: relative;
	
	display : flex;
	flex-direction : row;
	justify-content : space-around;
	align-items : center;
}

.div_002_001 > div:first-child  ,
.div_002_001 > div:nth-child(2) ,
.div_002_001 > div:nth-child(3) {
	flex-basis: 300px;
	height: 300px;
	
	display : flex;
	flex-direction : column;
	justify-content : flex-start;
	align-items : center;
}

.div_002_001 > div:first-child  > div:first-child ,
.div_002_001 > div:nth-child(2) > div:first-child ,
.div_002_001 > div:nth-child(3) > div:first-child {
	width: 100px;
	height: 100px;
	
	font-size: 35px;
	
	color: #ffffff;
	
	display : flex;
	flex-direction : column;
	justify-content : center;
	align-items : center;
	
	background-image : url('../assets/Slice 6.png');
	background-repeat : no-repeat;
	background-position : center;
	background-size : 100%;
}

.div_002_001 > div:first-child  > div:nth-child(2) ,
.div_002_001 > div:nth-child(2) > div:nth-child(2) ,
.div_002_001 > div:nth-child(3) > div:nth-child(2) {
	font-size: 16px;
	font-weight: 600;
	color: #742BFA;
	margin-top: 20px;
}

.div_002_001 > div:first-child  > div:nth-child(3) ,
.div_002_001 > div:nth-child(2) > div:nth-child(3) ,
.div_002_001 > div:nth-child(3) > div:nth-child(3) {
	font-size: 16px;
	color: #ffffff;
	margin-top: 20px;
	line-height: 28px;
}

/*** 虚线 ***/
.div_002_001 > div:nth-child(4) {
	width: 250px;
	height: 30px;
	
	position: absolute;
	top: 20px;
	left: 210px;
	
	background-image : url('../assets/Slice 7.png');
	background-repeat : no-repeat;
	background-position : center;
	background-size : 80%;
}

.div_002_001 > div:nth-child(5) {
	width: 250px;
	height: 30px;
	
	position: absolute;
	top: 65px;
	right: 210px;
	
	background-image : url('../assets/Slice 7.png');
	background-repeat : no-repeat;
	background-position : center;
	background-size : 80%;
	
	transform: scaleY(-1);
}

.div_002_light {
	width: 700px;
	height: 200px;
	
	position: absolute;
	top: 0px;
	right: 0px;
	
	background-image : url('../assets/Slice 24.png');
	background-repeat : no-repeat;
	background-position : center;
	background-size : 110%;
	
	transform: scaleY(-1);
}

/*** ************************************************* ***/

.div_003 {
	width: 100%;
	
	background-color: #040711;
	
	display : flex;
	flex-direction : column;
	justify-content : center;
	align-items : center;
}

.div_003_001 {
	width: 1000px;
	
	display : flex;
	flex-direction : row;
	justify-content : space-between;
	align-items : center;
}

.div_003_001 > div:first-child {
	flex-basis: 500px;
	height: 500px;
	
	background-image : url('../assets/Slice 8.png');
	background-repeat : no-repeat;
	background-position : center;
	background-size : 100%;
}

.div_003_001 > div:last-child {
	flex-basis: 500px;
	height: 500px;
	
	display : flex;
	flex-direction : column;
	justify-content : flex-start;
	align-items : center;
}

.div_003_001 > div:last-child > div:first-child {
	width: 450px;
	margin-left: 50px;
	
	font-size: 48px;
	font-weight: 600;
	
	margin-top: 30px;
	
	color: #FFFFFF;
	
	text-align: left;
}

.div_003_001 > div:last-child > div:nth-child(2) ,
.div_003_001 > div:last-child > div:nth-child(3) ,
.div_003_001 > div:last-child > div:nth-child(4) {
	width: 450px;
	margin-left: 50px;
	margin-top: 30px;
	
	font-size: 17px;
	color: #FFFFFF;
	
	text-align: left;
	line-height: 25px;
}

.div_003_001 > div:last-child > div:nth-child(2) {
	text-align: left;
	margin-top: 70px;
}

/*** ************************************************* ***/

.div_004 {
	width: 100%;
	
	padding-bottom: 100px;
	
	background-color: #040711;
	
	display : flex;
	flex-direction : column;
	justify-content : center;
	align-items : center;
}

.div_004_001 {
	width: 1000px;
	
	margin-top: 100px;
	
	display : flex;
	flex-direction : column;
	justify-content : flex-start;
	align-items : center;
	
	position: relative;
}

.div_004_001 > div:first-child {
	width: 650px;
	
	font-size: 48px;
	font-weight: 600;
	color: #FFFFFF;
	
	text-align: left;
	
	align-self: flex-start;
}

.div_004_001 > div:nth-child(2) {
	width: 550px;
	height: 380px;
	
	position: absolute;
	
	top: 30px;
	right: 0px;
	
	background-image : url('../assets/Slice 9.png');
	background-repeat : no-repeat;
	background-position : center;
	background-size : 120%;
}

.div_004_001 > div:nth-child(3) ,
.div_004_001 > div:nth-child(4) ,
.div_004_001 > div:nth-child(5) {
	width: 100%;
	font-size: 17px;
	color: #FFFFFF;
	text-align: left;
	margin-top: 30px;
}

.div_004_001 > div:nth-child(3) {
	margin-top: 300px;
}

/*** ************************************************* ***/

.div_005 {
	width: 100%;
	height: 860px;
	
	padding-bottom: 100px;
	
	background-color: #020308;
	
	display : flex;
	flex-direction : column;
	justify-content : flex-start;
	align-items : center;
}

.div_005_001 {
	width: 1000px;
	
	padding-bottom: 100px;
	
	display : flex;
	flex-direction : column;
	justify-content : center;
	align-items : center;
}

.div_005_001 > div:first-child {
	font-size: 48px;
	font-weight: 600;
	margin-top: 80px;
	color: #FFFFFF;
}

.div_005_001 > div:nth-child(2) {
	font-size: 20px;
	margin-top: 30px;
	color: #FFFFFF;
}

.div_005_001 > div:last-child {
	width: 100%;
	
	display : flex;
	flex-direction : row;
	justify-content : space-between;
	align-items : center;
	
	flex-wrap: wrap;
}

.div_005_001_block > div {
	flex-basis: 300px;
	height: 300px;
	
	margin-top: 40px;
	
	background-color: #101010;
	
	display : flex;
	flex-direction : column;
	justify-content : flex-start;
	align-items : center;
	
	border-radius: 20px;
}

.div_005_001_block > div:first-child  > div:first-child ,
.div_005_001_block > div:nth-child(2) > div:first-child ,
.div_005_001_block > div:nth-child(3) > div:first-child ,
.div_005_001_block > div:nth-child(4) > div:first-child ,
.div_005_001_block > div:nth-child(5) > div:first-child ,
.div_005_001_block > div:last-child   > div:first-child  {
	width: 80px;
	flex-basis: 80px;
	
	margin-top: 50px;
	
	background-repeat : no-repeat;
	background-position : center;
	background-size : 100%;
}

.div_005_001_block > div:first-child > div:first-child {
	background-image : url('../assets/Slice 10.png');
}

.div_005_001_block > div:nth-child(2) > div:first-child {
	background-image : url('../assets/Slice 11.png');
}

.div_005_001_block > div:nth-child(3) > div:first-child {
	background-image : url('../assets/Slice 12.png');
}

.div_005_001_block > div:nth-child(4) > div:first-child {
	background-image : url('../assets/Slice 13.png');
}

.div_005_001_block > div:nth-child(5) > div:first-child {
	background-image : url('../assets/Slice 14.png');
}

.div_005_001_block > div:last-child > div:first-child {
	background-image : url('../assets/Slice 15.png');
}

.div_005_001_block > div > div:nth-child(2) {
	font-size: 18px;
	font-weight: 600;
	color: #F1F1EF;
	
	margin-top: 25px;
}

.div_005_001_block > div > div:last-child {
	width: 90%;
	
	font-size: 12px;
	color: #ADADAD;
	
	margin-top: 25px;
}

/*** ************************************************* ***/

.div_006 {
	width: 100%;
	height: 650px;
	
	background-color: #040711;
	
	display : flex;
	flex-direction : column;
	justify-content : flex-start;
	align-items : center;
}

.div_006_001 {
	width: 1000px;
	
	display : flex;
	flex-direction : column;
	justify-content : center;
	align-items : center;
}

.div_006_001 > div:first-child {
	font-size: 48px;
	font-weight: 600;
	margin-top: 80px;
	color: #FFFFFF;
}

.div_006_001 > div:last-child {
	width: 100%;
	
	margin-top: 60px;
	
	display : flex;
	flex-direction : row;
	justify-content : space-between;
	align-items : center;
	
	flex-wrap: wrap;
}

.div_006_001_block > div {
	flex-basis: 300px;
	height: 150px;
	
	margin-top: 40px;
	
	display : flex;
	flex-direction : column;
	justify-content : flex-start;
	align-items : center;
	
	border-radius: 20px;
	border: 1px solid rgba(255,255,255,0.3);
}

.div_006_001_block > div:first-child  > div:first-child ,
.div_006_001_block > div:nth-child(2) > div:first-child ,
.div_006_001_block > div:nth-child(3) > div:first-child ,
.div_006_001_block > div:nth-child(4) > div:first-child ,
.div_006_001_block > div:nth-child(5) > div:first-child ,
.div_006_001_block > div:last-child   > div:first-child  {
	width: 300px;
	flex-basis: 150px;
	
	background-repeat : no-repeat;
	background-position : center;
	background-size : 100%;
}

.div_006_001_block > div:first-child > div:first-child {
	background-image : url('../assets/Slice 16.png');
}

.div_006_001_block > div:nth-child(2) > div:first-child {
	background-image : url('../assets/Slice 17.png');
}

.div_006_001_block > div:nth-child(3) > div:first-child {
	background-image : url('../assets/Slice 18.png');
}

.div_006_001_block > div:nth-child(4) > div:first-child {
	background-image : url('../assets/Slice 19.png');
}

.div_006_001_block > div:nth-child(5) > div:first-child {
	background-image : url('../assets/Slice 20.png');
}

.div_006_001_block > div:last-child > div:first-child {
	background-image : url('../assets/Slice 21.png');
}

/*** ************************************************* ***/

.div_007 {
	width: 100%;
	height: 900px;
	
	background-color: #040711;
	
	display : flex;
	flex-direction : column;
	justify-content : flex-start;
	align-items : center;
}

.div_007_001 {
	width: 1000px;
	
	display : flex;
	flex-direction : column;
	justify-content : center;
	align-items : center;
}

.div_007_001 > div:first-child {
	font-size: 48px;
	font-weight: 600;
	margin-top: 80px;
	color: #FFFFFF;
}

.div_007_001 > div:last-child {
	width: 100%;
	
	margin-top: 60px;
	
	display : flex;
	flex-direction : row;
	justify-content : flex-start;
	align-items : center;
}

.div_007_001_block > div:first-child {
	flex-basis: 450px;
	
	display : flex;
	flex-direction : column;
	justify-content : flex-start;
	align-items : center;
}

.div_007_001_block > div:first-child > div:first-child {
	width: 450px;
	flex-basis: 220px;
	
	background-image : url('../assets/Slice 22.png');
	background-repeat : no-repeat;
	background-position : center;
	background-size : 100%;
}

.div_007_001_block > div:first-child > div:last-child {
	width: 450px;
	flex-basis: 345px;
	
	margin-top: 10px;
	
	background-image : url('../assets/Slice 23.png');
	background-repeat : no-repeat;
	background-position : center;
	background-size : 100%;
}

.div_007_001_block > div:nth-child(2) {
	flex-basis: 100px;
	height: 545px;
	
	margin-left: 50px;
	background-color: #101010;
	
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	
	display : flex;
	flex-direction : column;
	justify-content : center;
	align-items : center;
	
	position: relative;
}

/*** 圆圈 ***/
.div_007_001_block > div:nth-child(2) > div:first-child  ,
.div_007_001_block > div:nth-child(2) > div:nth-child(3) ,
.div_007_001_block > div:nth-child(2) > div:nth-child(5) ,
.div_007_001_block > div:nth-child(2) > div:last-child  {
	width: 55px;
	flex-basis: 55px;
	
	font-size: 24px;
	font-weight: 600;
	
	margin: 40px 15px 40px 15px;
	
	color: #FFFFFF;
	background-color: #A990DD;
	border-radius: 50px;
	
	display : flex;
	flex-direction : column;
	justify-content : center;
	align-items : center;
}

/*** 竖线 ***/
.div_007_001_block > div:nth-child(2) > div:nth-child(2) ,
.div_007_001_block > div:nth-child(2) > div:nth-child(4) ,
.div_007_001_block > div:nth-child(2) > div:nth-child(6) {
	width: 2px;
	height: 60px;
	
	background-color: #A990DD;
	
	position: absolute;
}

.div_007_001_block > div:nth-child(2) > div:nth-child(2) {
	top: 108px;
	left: 48px;
}

.div_007_001_block > div:nth-child(2) > div:nth-child(4) {
	top: 244px;
	left: 48px;
}

.div_007_001_block > div:nth-child(2) > div:nth-child(6) {
	top: 378px;
	left: 48px;
}

.div_007_001_block > div:last-child {
	flex-basis: 400px;
	height: 545px;
	background-color: #101010;
	
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	
	display : flex;
	flex-direction : column;
	justify-content : center;
	align-items : flex-start;
}

.div_007_001_block > div:last-child > div {
	width: 90%;
	flex-basis: 135px;
	
	text-align: left;
	
	font-size: 18px;
	color: #FFFFFF;
	
	display : flex;
	flex-direction : row;
	justify-content : flex-start;
	align-items : center;
}

/*** ************************************************* ***/

.div_008 {
	width: 100%;
	height: 400px;
	
	color: #FFFFFF;
	background-color: #010207;
	
	background-image : url('../assets/Slice 24.png');
	background-repeat : no-repeat;
	background-position : center;
	background-size : 125%;
	
	display : flex;
	flex-direction : column;
	justify-content : flex-start;
	align-items : center;
	
	position: relative;
}

.div_008_001 {
	width: 1000px;
	
	display : flex;
	flex-direction : column;
	justify-content : flex-start;
	align-items : center;
}

.div_008_001 > div:first-child {
	width: 100%;
	flex-basis: 250px;
	
	display : flex;
	flex-direction : row;
	justify-content : space-around;
	align-items : center;
}

.div_008_001 > div:first-child > div {
	flex-basis: 200px;
	height: 250px;
	
	display : flex;
	flex-direction : column;
	justify-content : flex-start;
	align-items : flex-start;
}

.div_008_001 > div:first-child > div:first-child > div:first-child {
	font-size: 30px;
	color: #DA6CFF;
	margin-top: 50px;
}

.div_008_001 > div:first-child > div:first-child > div:last-child {
	font-size: 14px;
	margin-top: 30px;
}

.div_008_001 > div:first-child > div:nth-child(2) > div:first-child {
	font-size: 20px;
	margin-top: 55px;
}

.div_008_001 > div:first-child > div:nth-child(2) > div:nth-child(2) ,
.div_008_001 > div:first-child > div:nth-child(2) > div:nth-child(3) ,
.div_008_001 > div:first-child > div:nth-child(2) > div:nth-child(4) ,
.div_008_001 > div:first-child > div:nth-child(2) > div:nth-child(5) {
	font-size: 12px;
	margin-top: 20px;
}

.div_008_001 > div:last-child {
	width: 100%;
	flex-basis: 150px;
	
	display : flex;
	flex-direction : row;
	justify-content : center;
	align-items : center;
}

.div_008_001 > div:first-child > div:nth-child(3) > div:first-child {
	font-size: 20px;
	margin-top: 55px;
}

.div_008_001 > div:first-child > div:nth-child(3) > div:nth-child(2) ,
.div_008_001 > div:first-child > div:nth-child(3) > div:nth-child(3) ,
.div_008_001 > div:first-child > div:nth-child(3) > div:nth-child(4) ,
.div_008_001 > div:first-child > div:nth-child(3) > div:nth-child(5) {
	font-size: 12px;
	margin-top: 20px;
}

.div_008_001 > div:first-child > div:last-child > div:first-child {
	font-size: 20px;
	margin-top: 55px;
}

.div_008_001 > div:first-child > div:last-child > div:nth-child(2) ,
.div_008_001 > div:first-child > div:last-child > div:nth-child(3) ,
.div_008_001 > div:first-child > div:last-child > div:nth-child(4) ,
.div_008_001 > div:first-child > div:last-child > div:nth-child(5) {
	font-size: 12px;
	margin-top: 20px;
}

.div_008_001 > div:first-child > div:last-child > div:nth-child(4) {
	font-size: 20px;
	margin-top: 20px;
}

.div_008_001 > div:last-child {
	width: 100%;
	flex-basis: 150px;
	
	display : flex;
	flex-direction : row;
	justify-content : center;
	align-items : center;
	
	position: relative;
}

.div_008_001 > div:last-child > div:last-child {
	width: 180px;
	height: 40px;
	
	position: absolute;
	right: 6%;
	bottom: 60px;
	
	display : flex;
	flex-direction : row;
	justify-content : space-around;
	align-items : center;
}

.div_008_001 > div:last-child > div:last-child > div:first-child {
	width: 30px;
	height: 40px;
	
	display : flex;
	flex-direction : column;
	justify-content : center;
	align-items : center;
	
	background-image : url('../assets/Slice 25.png');
	background-repeat : no-repeat;
	background-position : center;
	background-size : 100%;
}

.div_008_001 > div:last-child > div:last-child > div:nth-child(2) {
	width: 30px;
	height: 40px;
	
	display : flex;
	flex-direction : column;
	justify-content : center;
	align-items : center;
	
	background-image : url('../assets/Slice 26.png');
	background-repeat : no-repeat;
	background-position : center;
	background-size : 100%;
}

.div_008_001 > div:last-child > div:last-child > div:last-child {
	width: 30px;
	height: 40px;
	
	display : flex;
	flex-direction : column;
	justify-content : center;
	align-items : center;
	
	background-image : url('../assets/Slice 27.png');
	background-repeat : no-repeat;
	background-position : center;
	background-size : 100%;
}

/*** ************************************************* ***/

.div_mobile_001 {
	width: 100%;
	height: 395px;
	
	background-color: #040711;
	
	display : flex;
	flex-direction : column;
	justify-content : flex-start;
	align-items : center;
}

.div_mobile_001_001 {
	width: 150px;
	height: 30px;
	
	margin: 20px 50px;

	background-image : url('../assets/web-logo.png');
	background-repeat : no-repeat;
	background-position : center;
	background-size : cover;
	
	align-self: flex-start;
}

.div_mobile_001_002 {
	width: 90%;
	flex-basis: 230px;
	
	background-image : url('../assets/Slice 4.png');
	background-repeat : no-repeat;
	background-position : center;
	background-size : cover;
	
	display : flex;
	flex-direction : column;
	justify-content : center;
	align-items : center;
}

.div_mobile_001_002 > div {
	width: 90%;
	flex-basis: 200px;
	
	background-image : url('../assets/Slice 5.png');
	background-repeat : no-repeat;
	background-position : center;
	background-size : cover;
	
	display : flex;
	flex-direction : column;
	justify-content : flex-start;
	align-items : center;
}

.div_mobile_001_002 > div > div:first-child {
	font-size: 26px;
	color: #FFFFFF;
	
	margin-top: 30px;
}

.div_mobile_001_002 > div > div:nth-child(2) {
	width: 80%;
	
	font-size: 12px;
	color: #FFFFFF;
	
	margin-top: 10px;
}

.div_mobile_001_002 > div > div:last-child {
	width: 130px;
	height: 30px;
	
	font-weight: 600;
	font-size: 10px;
	color: #FFFFFF;
	
	margin-top: 20px;

	border-radius: 25px;
	border: 2px solid #471EFF;
	
	display : flex;
	flex-direction : row;
	justify-content : space-around;
	align-items : center;
	
	cursor: pointer;
}

.div_mobile_001_003 {
	width: 90%;
	flex-basis: 65px;
	
	background-image : url('../assets/Frame 9.png');
	background-repeat : no-repeat;
	background-position : 50% 10px;
	background-size : 145px 90px;
	
	display : flex;
	flex-direction : column;
	justify-content : center;
	align-items : center;
	
	margin-top: 20px;
}

/*** ************************************************* ***/

.div_mobile_002 {
	width: 100%;
	height: 430px;
	
	background-color: #040711;
	
	display : flex;
	flex-direction : column;
	justify-content : flex-start;
	align-items : center;
}

.div_mobile_002_001 {
	width: 90%;
	height: 400px;
	
	background-color: #000000;
	
	display : flex;
	flex-direction : column;
	justify-content : flex-start;
	align-items : center;
}

.div_mobile_002_001 > div:first-child {
	font-size: 20px;
	line-height: 23px;
	color: #742BFA;
	
	margin-top: 20px;
}

.div_mobile_002_001 > div:nth-child(2) {
	width: 90%;
	
	font-weight: bold;
	font-size: 12px;
	color: #FFFFFF;
	line-height: 18px;
	text-align: left;
	
	margin-top: 20px;
}

.div_mobile_002_001_block {
	width: 100%;
	
	display : flex;
	flex-direction : column;
	justify-content : flex-start;
	align-items : center;
}

.div_mobile_002_001_block > div {
	width: 90%;
	flex-basis: 80px;
	
	display : flex;
	flex-direction : row;
	justify-content : flex-start;
	align-items : center;
	
	margin-top: 10px;
}

.div_mobile_002_001_block > div > div:first-child {
	flex-basis: 50px;
	height: 50px;
	
	background-image : url('../assets/Slice 6.png');
	background-repeat : no-repeat;
	background-position : center;
	background-size : cover;
	
	font-size: 20px;
	font-weight: 600;
	color: #ffffff;
	
	display : flex;
	flex-direction : column;
	justify-content : center;
	align-items : center;
}

.div_mobile_002_001_block > div > div:last-child {
	flex-basis: 280px;
	height: 50px;
	
	font-size: 12px;
	font-weight: 600;
	
	margin-left: 20px;
	
	display : flex;
	flex-direction : column;
	justify-content : space-around;
	align-items : flex-start;
}

.div_mobile_002_001_block > div > div:last-child > div:first-child {
	color: #742BFA;
}

.div_mobile_002_001_block > div > div:last-child > div:last-child {
	color: #FFFFFF;
	text-align: left;
}

/*** ************************************************* ***/

.div_mobile_003 {
	width: 100%;
	height: 250px;
	
	background-color: #040711;
	
	display : flex;
	flex-direction : column;
	justify-content : flex-start;
	align-items : center;
}

.div_mobile_003_001 {
	width: 90%;
	height: 180px;
	
	background-color: #000000;
	
	display : flex;
	flex-direction : row;
	justify-content : flex-start;
	align-items : center;
}

.div_mobile_003_001 > div:first-child {
	flex-basis: 150px;
	height: 150px;
	
	margin: 20px;
	
	background-image : url('../assets/Slice 8.png');
	background-repeat : no-repeat;
	background-position : center;
	background-size : cover;
}

.div_mobile_003_001 > div:last-child {
	flex-basis: 200px;
	height: 180px;

	display : flex;
	flex-direction : column;
	justify-content : flex-start;
	align-items : flex-start;
	
	font-weight: bold;
	font-size: 14px;
	color: #FFFFFF;
}

.div_mobile_003_001 > div:last-child > div:first-child {
	margin-top: 14px;
}

.div_mobile_003_001 > div:last-child > div:last-child {
	width: 90%;
	
	margin-top: 40px;

	font-size: 11px;
	font-weight: normal;
	color: #FFFFFF;
	
	text-align: left;
}

.div_mobile_003_002 {
	width: 90%;
	height: 40px;
	
	background-color: #0A0A0A;
	
	font-weight: bold;
	font-size: 14px;
	color: #FFFFFF;
	
	display : flex;
	flex-direction : column;
	justify-content : center;
	align-items : center;
}

/*** ************************************************* ***/

.div_mobile_004 {
	width: 100%;
	height: 560px;
	
	background-color: #040711;
	
	display : flex;
	flex-direction : column;
	justify-content : flex-start;
	align-items : center;
}

.div_mobile_004_001 {
	width: 90%;
	height: 490px;
	
	color: #FFFFFF;
	background-color: #000000;
	
	display : flex;
	flex-direction : column;
	justify-content : flex-start;
	align-items : center;
}

.div_mobile_004_001 > div:first-child {
	width: 90%;
	
	font-size: 24px;
	font-weight: 600;
	
	text-align: left;
	
	margin-top: 10px;
}

.div_mobile_004_001 > div:nth-child(2) {
	width: 100%;
	flex-basis: 300px;
	
	background-image : url('../assets/Slice 9.png');
	background-repeat : no-repeat;
	background-position : center;
	background-size : cover;
}

.div_mobile_004_001 > div:last-child {
	width: 90%;
	
	font-size: 15px;
	
	text-align: left;
	
	margin-top: 0px;
}

.div_mobile_004_002 {
	width: 90%;
	height: 40px;
	
	background-color: #0A0A0A;
	
	font-weight: bold;
	font-size: 14px;
	color: #FFFFFF;
	
	display : flex;
	flex-direction : column;
	justify-content : center;
	align-items : center;
}

/*** ************************************************* ***/

.div_mobile_005 {
	width: 100%;
	height: 530px;
	
	background-color: #040711;
	
	display : flex;
	flex-direction : column;
	justify-content : flex-start;
	align-items : center;
}

.div_mobile_005_001 {
	width: 90%;
	height: 460px;
	
	color: #FFFFFF;
	background-color: #000000;
	
	display : flex;
	flex-direction : column;
	justify-content : flex-start;
	align-items : center;
}


.div_mobile_005_002 {
	width: 90%;
	height: 40px;
	
	background-color: #0A0A0A;
	
	font-weight: bold;
	font-size: 14px;
	color: #FFFFFF;
	
	display : flex;
	flex-direction : column;
	justify-content : center;
	align-items : center;
}

.div_mobile_005_001 > div:first-child {
	font-size: 24px;
	font-weight: 600;
	
	margin-top: 20px;
}

.div_mobile_005_001 > div:nth-child(2) {
	font-size: 14px;
	font-weight: 600;
	
	margin-top: 10px;
}

.div_mobile_005_001 > div:nth-child(3) ,
.div_mobile_005_001 > div:nth-child(4) ,
.div_mobile_005_001 > div:nth-child(5) {
	width: 90%;
	flex-basis: 100px;
	background-color: #101010;
	border-radius: 10px;
	
	margin-top: 20px;
	
	display : flex;
	flex-direction : row;
	justify-content : center;
	align-items : center;
}

.div_mobile_005_001 > div:nth-child(3) > div:first-child ,
.div_mobile_005_001 > div:nth-child(4) > div:first-child ,
.div_mobile_005_001 > div:nth-child(5) > div:first-child {
	flex-basis: 50px;
	height: 50px;
	
	background-repeat : no-repeat;
	background-position : center;
	background-size : cover;
}

.div_mobile_005_001 > div:nth-child(3) > div:first-child {
	background-image : url('../assets/Slice 10.png');
}

.div_mobile_005_001 > div:nth-child(4) > div:first-child {
	background-image : url('../assets/Slice 11.png');
}

.div_mobile_005_001 > div:nth-child(5) > div:first-child {
	background-image : url('../assets/Slice 12.png');
}

.div_mobile_005_001 > div:nth-child(3) > div:last-child ,
.div_mobile_005_001 > div:nth-child(4) > div:last-child ,
.div_mobile_005_001 > div:nth-child(5) > div:last-child {
	flex-basis: 250px;
	height: 80px;
	
	font-size: 12px;
	
	margin-left: 20px;
	
	display : flex;
	flex-direction : column;
	justify-content : space-around;
	align-items : flex-start;
}

.div_mobile_005_001 > div:nth-child(3) > div:last-child > div:first-child ,
.div_mobile_005_001 > div:nth-child(4) > div:last-child > div:first-child ,
.div_mobile_005_001 > div:nth-child(5) > div:last-child > div:first-child {
	font-size: 15px;
	font-weight: 600;
	text-align: left;
}

.div_mobile_005_001 > div:nth-child(3) > div:last-child > div:last-child ,
.div_mobile_005_001 > div:nth-child(4) > div:last-child > div:last-child ,
.div_mobile_005_001 > div:nth-child(5) > div:last-child > div:last-child {
	font-size: 11px;
	text-align: left;
}

/*** ************************************************* ***/

.div_mobile_006 {
	width: 100%;
	height: 250px;
	
	background-color: #040711;
	
	display : flex;
	flex-direction : column;
	justify-content : flex-start;
	align-items : center;
}

.div_mobile_006_001 {
	width: 90%;
	height: 220px;
	
	color: #FFFFFF;
	background-color: #000000;
	
	display : flex;
	flex-direction : column;
	justify-content : flex-start;
	align-items : center;
}

.div_mobile_006_001 > div:first-child {
	font-size: 24px;
	font-weight: 600;
	
	margin-top: 20px;
}

.div_mobile_006_001 > div:last-child {
	width: 90%;
	
	margin-top: 10px;
	
	display : flex;
	flex-direction : row;
	justify-content : space-between;
	align-items : center;
	
	flex-wrap: wrap;
}

.div_mobile_006_001_block > div {
	flex-basis: 100px;
	height: 50px;
	
	margin-top: 10px;
	
	display : flex;
	flex-direction : row;
	justify-content : flex-start;
	align-items : center;
	
	border-radius: 10px;
	border: 1px solid rgba(255,255,255,0.3);
}

.div_mobile_006_001_block > div:first-child  > div:first-child ,
.div_mobile_006_001_block > div:nth-child(2) > div:first-child ,
.div_mobile_006_001_block > div:nth-child(3) > div:first-child ,
.div_mobile_006_001_block > div:nth-child(4) > div:first-child ,
.div_mobile_006_001_block > div:nth-child(5) > div:first-child ,
.div_mobile_006_001_block > div:last-child   > div:first-child  {
	flex-basis: 100px;
	height: 50px;
	
	background-repeat : no-repeat;
	background-position : center;
	background-size : 100%;
}

.div_mobile_006_001_block > div:first-child > div:first-child {
	background-image : url('../assets/Slice 16.png');
}

.div_mobile_006_001_block > div:nth-child(2) > div:first-child {
	background-image : url('../assets/Slice 17.png');
}

.div_mobile_006_001_block > div:nth-child(3) > div:first-child {
	background-image : url('../assets/Slice 18.png');
}

.div_mobile_006_001_block > div:nth-child(4) > div:first-child {
	background-image : url('../assets/Slice 19.png');
}

.div_mobile_006_001_block > div:nth-child(5) > div:first-child {
	background-image : url('../assets/Slice 20.png');
}

.div_mobile_006_001_block > div:last-child > div:first-child {
	background-image : url('../assets/Slice 21.png');
}

/*** ************************************************* ***/

.div_mobile_007 {
	width: 100%;
	height: 330px;
	
	background-color: #040711;
	
	display : flex;
	flex-direction : column;
	justify-content : flex-start;
	align-items : center;
}

.div_mobile_007_001 {
	width: 90%;
	height: 280px;
	
	color: #FFFFFF;
	background-color: #000000;
	
	display : flex;
	flex-direction : column;
	justify-content : flex-start;
	align-items : center;
}

.div_mobile_007_001 > div:first-child {
	font-size: 24px;
	font-weight: 600;
	margin-top: 10px;
}

.div_mobile_007_001_block {
	width: 100%;
	
	margin-top: 10px;
	
	display : flex;
	flex-direction : row;
	justify-content : center;
	align-items : center;
}

.div_mobile_007_001_block > div:first-child {
	flex-basis: 140px;
	
	display : flex;
	flex-direction : column;
	justify-content : flex-start;
	align-items : center;
}

.div_mobile_007_001_block > div:first-child > div:first-child {
	width: 140px;
	flex-basis: 70px;
	
	background-image : url('../assets/Slice 22.png');
	background-repeat : no-repeat;
	background-position : center;
	background-size : 100%;
}

.div_mobile_007_001_block > div:first-child > div:last-child {
	width: 140px;
	flex-basis: 110px;
	
	margin-top: 25px;
	
	background-image : url('../assets/Slice 23.png');
	background-repeat : no-repeat;
	background-position : center;
	background-size : 100%;
}

.div_mobile_007_001_block > div:nth-child(2) {
	flex-basis: 50px;
	height: 200px;
	
	margin-left: 10px;
	background-color: #101010;
	
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	
	display : flex;
	flex-direction : column;
	justify-content : center;
	align-items : center;
	
	position: relative;
}

/*** 圆圈 ***/
.div_mobile_007_001_block > div:nth-child(2) > div:first-child  ,
.div_mobile_007_001_block > div:nth-child(2) > div:nth-child(3) ,
.div_mobile_007_001_block > div:nth-child(2) > div:nth-child(5) ,
.div_mobile_007_001_block > div:nth-child(2) > div:last-child  {
	width: 25px;
	flex-basis: 25px;
	
	font-size: 14px;
	font-weight: 600;
	
	margin: 13px 10px 13px 10px;
	
	color: #FFFFFF;
	background-color: #A990DD;
	border-radius: 50px;
	
	display : flex;
	flex-direction : column;
	justify-content : center;
	align-items : center;
	
}

/*** 竖线 ***/
.div_mobile_007_001_block > div:nth-child(2) > div:nth-child(2) ,
.div_mobile_007_001_block > div:nth-child(2) > div:nth-child(4) ,
.div_mobile_007_001_block > div:nth-child(2) > div:nth-child(6) {
	width: 2px;
	height: 20px;
	
	background-color: #A990DD;
	
	position: absolute;
}

.div_mobile_007_001_block > div:nth-child(2) > div:nth-child(2) {
	top: 40px;
	left: 25px;
}

.div_mobile_007_001_block > div:nth-child(2) > div:nth-child(4) {
	top: 90px;
	left: 25px;
}

.div_mobile_007_001_block > div:nth-child(2) > div:nth-child(6) {
	top: 140px;
	left: 25px;
}

.div_mobile_007_001_block > div:last-child {
	flex-basis: 160px;
	height: 200px;
	background-color: #101010;
	
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	
	display : flex;
	flex-direction : column;
	justify-content : center;
	align-items : flex-start;
}

.div_mobile_007_001_block > div:last-child > div {
	width: 97%;
	flex-basis: 50px;
	
	text-align: left;
	
	font-size: 12px;
	color: #FFFFFF;
	
	display : flex;
	flex-direction : row;
	justify-content : flex-start;
	align-items : center;
}

/*** ************************************************* ***/
.div_mobile_008 {
	width: 100%;
	height: 520px;
	
	color: #FFFFFF;
	background-color: #010207;
	
	display : flex;
	flex-direction : column;
	justify-content : flex-start;
	align-items : center;
	
	background-image : url('../assets/Slice 24.png');
	background-repeat : no-repeat;
	background-position : center;
	background-size : 415%;
}

.div_mobile_008 > div:first-child {
	width: 100%;
	flex-basis: 360px;
	
	margin-top: 20px;
	
	font-size: 12px;
	
	display : flex;
	flex-direction : row;
	justify-content : space-around;
	align-items : center;
	
	flex-wrap: wrap;
}

.div_mobile_008 > div:first-child > div:first-child {
	flex-basis: 30%;
	height: 180px;
	
	padding-left: 40px;
	
	display : flex;
	flex-direction : column;
	justify-content : flex-start;
	align-items : flex-start;
}

.div_mobile_008 > div:first-child > div:nth-child(2) {
	flex-basis: 30%;
	height: 180px;
	
	padding-left: 0px;
	
	display : flex;
	flex-direction : column;
	justify-content : flex-start;
	align-items : flex-start;
}

.div_mobile_008 > div:first-child > div:nth-child(3) {
	flex-basis: 30%;
	height: 180px;
	
	padding-left: 40px;
	
	display : flex;
	flex-direction : column;
	justify-content : flex-start;
	align-items : flex-start;
}

.div_mobile_008 > div:first-child > div:last-child {
	flex-basis: 30%;
	height: 180px;
	
	padding-left: 0px;
	
	display : flex;
	flex-direction : column;
	justify-content : flex-start;
	align-items : flex-start;
}

.div_mobile_008 > div:first-child > div:first-child > div  ,
.div_mobile_008 > div:first-child > div:nth-child(2) > div ,
.div_mobile_008 > div:first-child > div:nth-child(3) > div ,
.div_mobile_008 > div:first-child > div:last-child > div   {
	margin-top: 10px;
	text-align: left;
}

.div_mobile_008 > div:first-child > div:first-child > div:first-child  ,
.div_mobile_008 > div:first-child > div:nth-child(2) > div:first-child ,
.div_mobile_008 > div:first-child > div:nth-child(3) > div:first-child ,
.div_mobile_008 > div:first-child > div:last-child > div:first-child   {
	margin-top: 20px;
	font-size: 14px;
}

.div_mobile_008 > div:nth-child(2) {
	width: 100%;
	flex-basis: 100px;
	
	display : flex;
	flex-direction : row;
	justify-content : center;
	align-items : center;
}

.div_mobile_008 > div:nth-child(2) > div:first-child {
	flex-basis: 50%;
	height: 100px;
	
	margin-left: 20px;
	
	display : flex;
	flex-direction : column;
	justify-content : center;
	align-items : flex-start;
}

.div_mobile_008 > div:nth-child(2) > div:first-child > div {
	width: 100%;
	text-align: left;
}

.div_mobile_008 > div:nth-child(2) > div:first-child > div:last-child {
	font-size: 10px;
	margin-top: 5px;
}

.div_mobile_008 > div:nth-child(2) > div:last-child {
	flex-basis: 30%;
	height: 100px;
	
	display : flex;
	flex-direction : row;
	justify-content : space-around;
	align-items : center;
}

.div_mobile_008 > div:nth-child(2) > div:last-child > div:first-child {
	width: 30px;
	height: 60px;

	background-image : url('../assets/Slice 25.png');
	background-repeat : no-repeat;
	background-position : center;
	background-size : 100%;
}

.div_mobile_008 > div:nth-child(2) > div:last-child > div:nth-child(2) {
	width: 30px;
	height: 60px;

	background-image : url('../assets/Slice 26.png');
	background-repeat : no-repeat;
	background-position : center;
	background-size : 100%;
}

.div_mobile_008 > div:nth-child(2) > div:last-child > div:last-child {
	width: 30px;
	height: 60px;

	background-image : url('../assets/Slice 27.png');
	background-repeat : no-repeat;
	background-position : center;
	background-size : 100%;
}

.div_mobile_008 > div:nth-child(3) {
	width: 100%;
	flex-basis: 50px;
}
</style>